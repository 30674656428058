import './css/Card.scss';


import React from 'react';

import { Box } from '@mui/material';
import Title from './Title'


function Card (props: any) {
    
    let className = "card ";
    switch(props.type){
        case "floating": {
            className += 'floating ';
            break;
        }
    }

    if(props.class){
        className = className + ' ' + props.class;
    }


    // console.log(props)
    let childrens = null;
    if( props.children && Array.isArray(props.children) ){
        childrens = props.children.map((component: any, i: number) => component)
    }else{
        childrens = props.children
    }
    // console.log(childrens)
    const {icon, title, actions} = props;

    return (
        <Box className={className} sx={props.style}>
            { (icon || title || actions) &&
                <div className='card-header'>
                    {(icon || title) &&
                    <div className='card-title'>
                        {icon &&
                        <div className="card-icon">
                            {icon}
                        </div>
                        }

                        <Title>{title}</Title>
                    </div>
                    }
                    {actions &&
                    <div className='card-actions'>
                        {actions}
                    </div>
                    }
                </div>
            }
            <div className='card-body'>
            {
                childrens
            }
            {
            props.bubbles &&
                <>
                    <div className="bubble small"></div>
                    <div className="bubble s-medium"></div>
                    <div className="bubble medium"></div>
                    <div className="bubble large"></div>
                    <div className="bubble small-l"></div>
                    <div className="bubble small-2"></div>
                </>
            }
            </div>
        </Box>
    );
}

export default React.memo(Card);