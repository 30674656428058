import './css/History.scss';
import React, { useState, useEffect, useContext } from 'react';


import Axios from '../helpers/axios';
import config from '../config';

import { ctxSession } from '../store';


import BookRoundedIcon from '@mui/icons-material/BookRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';

import { useTranslation } from 'react-i18next';


function History() {
  const { t } = useTranslation();

  const session = useContext(ctxSession);

  const [transcriptions, setTranscriptions] = useState<any[]>([]);

  useEffect(() => {
    const onSuccess = (response: any) => {
      setTranscriptions(response.data.transcriptions)
    };

    const onError = () => {

    }

    Axios(session, 'get', `${config.api}/transcription-history`, onSuccess, onError);

  }, []);


  const handleDownload = (downloadToken: string) => {
    // Creazione di un elemento <a> nascosto
    const link = document.createElement('a');
    link.style.display = 'none';
    document.body.appendChild(link);

    // Impostazione dell'URL e dei parametri di query
    link.href = `${config.api}/download?token=${downloadToken}`;

    // Innesco del download
    link.click();

    // Rimozione dell'elemento <a> dal DOM
    document.body.removeChild(link);
  };



  return (
    <div id="history">

      <h1><BookRoundedIcon /> {t('history')}</h1>

      <div className="history">
        <div className="list">

          {transcriptions.map((t: any, k: number) =>
            <div className='item' key={k}>
              <div className='desc'>

                <div className="icon">
                  <DescriptionRoundedIcon />
                </div>
                <div className="title">
                  {t.file_name}
                </div>
              </div>
              <div className='action'>
                <div className="download" onClick={() => handleDownload(t.downloadToken)}>
                  <FileDownloadRoundedIcon />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default History;
