import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '../components/Card';
import CustomButton from '../components/CustomButton';
import CustomInput from '../components/CustomInput';
import Axios from '../helpers/axios';
import config from '../config';

import { ctxSession, ctxSnackbar } from '../store';

import { useTranslation } from 'react-i18next';

const RequestResetPassword: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const session = useContext(ctxSession);
  const snackbar = useContext(ctxSnackbar);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [errorAnimation, setErrorAnimation] = useState(false);

  const animateError = () => {
    setErrorAnimation(true);
    setTimeout(() => {
      setErrorAnimation(false);
    }, 1000);
  };

  const requestReset = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const email = formData.get("email") as string;

    if (email) {
      setLoading(true);

      const onSuccess = (response: any) => {
        snackbar?.set({ message: 'Email sent successfully', severity: 'success', millis: 3000 });
        navigate('/login');
      }

      const onError = (response: any) => {
        snackbar?.set({ message: response.response.data.errors[0].msg, severity: 'error', millis: 3000 });
      }

      Axios(session, 'post', `${config.api}/request-password-reset`, onSuccess, onError, { email });
    } else {
      setError("Email address is required");
      animateError();
    }
  };

  return (
    <div id="Auth">
      <Card type="floating" className="shadow">
        <div className="logo">
          <img className={`mb-3 ${loading ? 'spin' : ''} ${errorAnimation ? 'shake' : ''}`} src={/* importer.img.require('Logo.svg')*/ ""} alt="" />
          <h4 className='mb-0'>{t('requestPasswordReset')}</h4>
        </div>
        <div className="my-4">
          <form onSubmit={requestReset}>
            <div className="mb-3">
              <CustomInput name="email" variant="cloud" type="email" placeholder={t('email')} required={true} />
            </div>
            <div>
              <CustomButton variant="primary" type={"submit"} className="w-100 mb-2">{t('requestReset')}</CustomButton>
            </div>
          </form>
        </div>
        <a className='' onClick={() => { navigate('/login') }}><strong>{t('back_to_login')}</strong></a>
      </Card>
    </div>
  );
};

export default React.memo(RequestResetPassword);
