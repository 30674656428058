import './css/Transcriptor.scss';
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import TranscriptionSettingsModal from '../components/TranscriptionSettingsModal';
import Dropzone from '../components/Dropzone';
import { ctxSession } from '../store';
import Axios from '../helpers/axios';
import config from '../config';
import { useTranslation } from 'react-i18next';

import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';


function Transcriptor() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const session = useContext(ctxSession);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [transcriptionSeconds, setTranscriptionSeconds] = useState<number | null>(null);
  const [shouldFetchSeconds, setShouldFetchSeconds] = useState(false); // flag di stato per segnalare quando aggiornare i secondi
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (shouldFetchSeconds) {
      fetchTranscriptionSeconds();
      setShouldFetchSeconds(false);
    }
  }, [shouldFetchSeconds]);

  const handleTranscriptionSuccess = () => {
    setShouldFetchSeconds(true);
  };


  const fetchTranscriptionSeconds = () => {
    if (session && session.data?.userData) {
      const onSuccess = (response: any) => {
        setTranscriptionSeconds(response.data.transcription_seconds);
      };
      const onError = (response: any) => {
        console.error("Error fetching transcriptionSeconds:", response);
      };
      Axios(session, 'get', `${config.api}/account/transcriptionSeconds`, onSuccess, onError);
    }
  };

  useEffect(() => {
    fetchTranscriptionSeconds();
  }, [session]);

  // secondsToTime in format AAAA:MM:DD:HH:MM:SS --> aggiorna dopo trascrizione con un altra chiamata
  const secondsToTime = (seconds: number) => {
    const years = Math.floor(seconds / 31536000).toString();
    const months = Math.floor((seconds % 31536000) / 2592000).toString().padStart(2, '0');
    const days = (Math.floor((seconds % 31536000) / 86400) - (Number(months) * 30)).toString().padStart(2, '0');
    const hours = Math.floor(((seconds % 31536000) % 86400) / 3600).toString().padStart(2, '0');
    const minutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60).toString().padStart(2, '0');
    const secs = Math.floor((((seconds % 31536000) % 86400) % 3600) % 60).toString().padStart(2, '0');


    let str = new Array();
    // Costruiamo gradualmente la stringa del tempo
    if (years > '00') {
      str.push(years)
    }
    if (months > '00') {
      str.push(months)
    }
    if (days > '00') {
      str.push(days)
    }

    str.push(hours)
    str.push(minutes)
    str.push(secs)

    return str.join(':');
  };


  useEffect(() => {
    if (session?.data?.userData == null) {
      setTranscriptionSeconds(null);
    }
  }, [session]);

  const updateTranscriptionSeconds = (newSeconds: number) => {
    setTranscriptionSeconds(newSeconds);
  };


  return (
    <div id="transcriptor">
      {
        session?.data?.userData
        &&
        <h5>{t('Welcome to our transcription platform!')}</h5>
      }
      {
        session?.data?.userData?.friendCode
          ?
          <h5>{t('Your friend code is: {{friendCode}}', { friendCode: session?.data?.userData?.friendCode })}</h5>
          :
          <></>
      }
      {
        transcriptionSeconds != null
          ?
          <h5>{t('Time available: {{time}}', { time: secondsToTime(transcriptionSeconds) })}</h5>
          :
          <></>
      }
      <Dropzone
        accept=".m4a,.mp3,.webm,.mp4,.mpga,.wav,.mpeg,.mov,.mkv"
        uploadButton={true}
        showProgressBar={true}
        onFileDropped={(e: any) => {
          if (session && session.data?.userData) {
            // Aggiungere la verifica qui
            if (e.target && e.target.files && e.target.files.length > 0) {
              // Resetta lo stato a null per forzare un aggiornamento
              setSelectedFile(null);
              // Imposta il nuovo file
              setSelectedFile(e.target.files[0]);
            }
          } else {
            navigate('/login');
          }
        }}

      />


      {selectedFile && (selectedFile.name.endsWith('.mp4') || selectedFile.name.endsWith('.mp3') || selectedFile.name.endsWith('.m4a') || selectedFile.name.endsWith('.wav') || selectedFile.name.endsWith('.mpeg') || selectedFile.name.endsWith('.mpga') || selectedFile.name.endsWith('.webm' ) || selectedFile.name.endsWith('.mov') || selectedFile.name.endsWith('.mkv')) && (
        <TranscriptionSettingsModal
          file={selectedFile}
          availableSeconds={transcriptionSeconds}
          onClose={() => {
            setSelectedFile(null);
            handleTranscriptionSuccess();
          }}
          updateParentTranscriptionSeconds={updateTranscriptionSeconds}  // Aggiungi questa linea
        />
      )}


      {
        <h5>{t('supportedFormats')}</h5>
      }
      {
        <h5 onClick={() => setIsExpanded(!isExpanded)}>
          {t('supportedLanguages')}
          <span>
              <InfoRoundedIcon />
          </span>
          
        </h5>
      }
      {isExpanded &&
        <div className='languages'>
          <h5>{t('supportedLanguages')}</h5>
            <span
            className="close"
            onClick={() => {
              setIsExpanded(false);
            }}
          >
            <CloseRoundedIcon />
          </span>
          {(" Afrikaans, Arabic, Armenian, Azerbaijani, Belarusian, Bosnian, Bulgarian, Catalan, Chinese, Croatian, Czech, Danish, Dutch, English, Estonian, Finnish, French, Galician, German, Greek, Hebrew, Hindi, Hungarian, Icelandic, Indonesian, Italian, Japanese, Kannada, Kazakh, Korean, Latvian, Lithuanian, Macedonian, Malay, Marathi, Maori, Nepali, Norwegian, Persian, Polish, Portuguese, Romanian, Russian, Serbian, Slovak, Slovenian, Spanish, Swahili, Swedish, Tagalog, Tamil, Thai, Turkish, Ukrainian, Urdu, Vietnamese, and Welsh.")}
        </div>
      }
    </div>
  );
};

export default Transcriptor;
