import axios from 'axios';

const Catch = (error, auth) => {
  if(error && error.response && error.response.status){
    switch(error.response.status){
      case 401:{
        localStorage.removeItem('session');
        if(auth.data && auth.set){
          auth.set(null);
        }
        return false;
      }
      default: {
        return true;
      }
    }
  }
}

const Axios = (auth, method, url, then, _catch, data = {}, config = {}, dataType = 'formData') => {
  let reqConfig = {
    withCredentials: true,
    ...config,
  }


  if( dataType === 'formData' ){
    reqConfig.headers = {
      ...reqConfig.headers,
      //"Content-Type": "multipart/form-data",
    }
  }

  const request = axios[method];
  switch(method){
    case 'get':
    case 'delete':{
      request(url, reqConfig)
        .then((result) => result.status !== 204 ? then(result) : console.log('204 : No Content'))
        .catch(error => {
          if(Catch(error, auth)){
            _catch(error);
          }
        });
      break;
    }
    case 'post':
    case 'put':
    case 'patch':{
      request(url, data, reqConfig)
        .then((result) => result.status !== 204 ? then(result) : console.log('204 : No Content'))
        .catch(error => {
          if(Catch(error, auth)){
            _catch(error);
          }
        });
      break;
    }
    default: {
      console.error('Bad method')
      break;
    }
  }
}

export default Axios;
