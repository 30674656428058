import './css/Home.scss';
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ctxSession } from '../store';
import Axios from '../helpers/axios';
import config from '../config';
import importer from '../helpers/importer';

import { Outlet } from 'react-router-dom';

import RttRoundedIcon from '@mui/icons-material/RttRounded';
import BookRoundedIcon from '@mui/icons-material/BookRounded';
import StorefrontRoundedIcon from '@mui/icons-material/StorefrontRounded';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
// import AccessibilityNewRoundedIcon from '@mui/icons-material/AccessibilityNewRounded';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';

import Tooltip from '@mui/material/Tooltip';

import { useTranslation } from 'react-i18next';

function Home() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  // console.log(location)
  const session = useContext(ctxSession);

  const logout = () => {
    const onSuccess = () => {
      if (session && session.set) {
        localStorage.clear();
        session.set({ ...session.data, userData: null, token: '' });
        navigate('/');
      }
    }
    const onError = () => {
    }

    Axios(session, 'get', `${config.api}/auth/logout`, onSuccess, onError);
  }

  return (
    <div id="home">

      <div className="logo">
        <img src={importer.img.require('logo.svg')} alt="" />
      </div>

      <main>
        <article>
          <Outlet />
        </article>
        <aside>
          <div className="menu">

            <Tooltip title={t('transcriptor')} placement="right">
              <div className={pathname === '/' ? 'selected' : ''}
                onClick={() => { navigate('/') }}
              >
                <RttRoundedIcon fontSize="large" />
              </div>
            </Tooltip>
            {
              session?.data?.userData
                ?
                <>

                  <Tooltip title={t('history')} placement="right">
                    <div className={pathname === '/history' ? 'selected' : ''}
                      onClick={() => { navigate('/history') }}
                    >
                      <BookRoundedIcon fontSize="large" />
                    </div>
                  </Tooltip>


                  <Tooltip title={t('market')} placement="right">
                    <div className={pathname === '/market' ? 'selected' : ''}
                      onClick={() => { navigate('/market') }}
                    >
                      <StorefrontRoundedIcon fontSize="large" />
                    </div>
                  </Tooltip>

                  <Tooltip title={t('orders')} placement="right">
                    <div className={pathname === '/orders' ? 'selected' : ''}
                      onClick={() => { navigate('/orders') }}
                    >
                      <HistoryRoundedIcon fontSize="large" />
                    </div>
                  </Tooltip>

                  {/* <Tooltip title="Profile" placement="right">
                    <div className={pathname === '/profile' ? 'selected' : ''}
                      onClick={() => {navigate('/profile')}}
                    >
                      <AccessibilityNewRoundedIcon fontSize="large" />
                    </div>
                  </Tooltip> */}

                </>
                :
                <></>
            }

          </div>

          <div className="other">
            {
              session?.data?.userData
                ?
                <Tooltip title={t('logout')} placement="right">
                  <div
                    onClick={() => { logout() }}
                  ><LogoutRoundedIcon fontSize="large" /></div>
                </Tooltip>
                :
                <Tooltip title={t('login')} placement="right">
                  <div
                    onClick={() => {
                      navigate('/login');
                    }}
                  >
                    <LoginRoundedIcon fontSize="large" />
                  </div>
                </Tooltip>
            }
          </div>

        </aside>
      </main>

    </div>
  );
};

export default Home;
