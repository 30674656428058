import './css/CustomSnackbarContainer.css';

import React, { useState, useRef, useMemo } from 'react';

import { ctxSnackbar } from '../store';

import CustomSnackbar from './CustomSnackbar';

import Datetime from '../helpers/datetime';

import { SnackbarModel  } from '../models'

const CustomSnackbarContainer = (props:any) => {
    
    const [snack, setSnack] = useState<(SnackbarModel|null)[]>([]);//useState<SnackbarModel[] | null>([]);
    const snackRef = useRef<any[]>();
    
    snackRef.current = snack;
        

    let childrens = null;
    if( props.children && Array.isArray(props.children) ){
        childrens = props.children.map((component: any, i: number) => component)
    }else{
        childrens = props.children
    }
    
    const setter = (value:SnackbarModel) => {
        const timestamp = new Datetime().getUnixTimestampMillis();
        
        value.exp_timestamp = timestamp + (value.millis ? value.millis : 2000);

        const temp = [
            ...snack.map( (v:SnackbarModel|null) => {
                if(v && v.exp_timestamp && v.exp_timestamp < timestamp){
                    return null;
                }else{
                    return v;
                }
            }),
            value
        ];
        // console.log(temp)
        setSnack([ ...temp ]);

        return temp.length - 1;
    }

    const hide = (index:number) => {
        // console.log(index);
        
        if(snackRef.current && snackRef.current[index]){
            const temp = [...snackRef.current];
            
                temp[index].persistent = false;
                temp[index].millis = 1;

                setSnack([ ...temp ]);

        }
    }
    

    const snackbarContainer = useMemo( () => {
        return (
            <div id='snackbarContainer'>
                { 
                snack.map((s:SnackbarModel|null, i:number) => {
                    // const timestamp = new Datetime().getUnixTimestampMillis();
                    if( s ){
                        return (
                        <CustomSnackbar key={i} {...s} />
                        );
                    }else{
                        return (null);
                    }
                })
                }
            </div>
        )
    }, [snack]);

    return (
        <>
        <ctxSnackbar.Provider value={ {data: snack, set: setter, hide} }>
        {
            childrens
        }
        </ctxSnackbar.Provider>
        {snackbarContainer}
        </>
    );
}

export default CustomSnackbarContainer;