import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Traduzioni
const resources = {
    en: {
        translation: {
            // Transcriptor
            "Welcome to our transcription platform!": "Welcome to our transcription platform!",
            "Your friend code is: {{friendCode}}": "Your friend code is: {{friendCode}}",
            "Time available: {{time}}": "Time available: {{time}}",
            "note1": "Note: 1h at 0.99€.",
            "note2": "To purchase credits and try the service contact:",
            "mail": "mail: support@letterlake.com",
            "telegram": "telegram: @Jason_Venom",
            "supportedFormats": "Supported formats: m4a, mp3, webm, mp4, mpga, wav, mpeg, mkv, mkv",
            "supportedLanguages": "Supported languages: ",
            "clickToExpand": "Click to expand...",
            // SignUp
            "accountSignUp": "Account Sign Up",
            "email": "Email",
            "password": "Password",
            "confirmPassword": "Confirm Password",
            "firstname": "Firstname",
            "lastname": "Lastname",
            "birthDate": "Birth Date",
            "friendCode": "Friend Code",
            "signUp": "Sign Up",
            "allFieldsRequired": "All fields are required",
            "invalidEmail": "Invalid email format",
            "passwordMismatch": "Passwords do not match",
            // LogIn
            "signInToYourAccount": "Sign in to your account",
            // Rimuovi duplicati
            // "email": "Email",
            // "password": "Password",
            "login": "Login",
            "forgotPassword": "Forgot password?",
            "register": "Register",
            "badCredentials": "Invalid Credentials",
            // RequestResetPassword
            "requestPasswordReset": "Request Password Reset",
            // Rimuovi duplicati
            // "email": "Email",
            "requestReset": "Request Reset",
            "emailAddressIsRequired": "Email address is required",
            "emailSentSuccessfully": "Email sent successfully",
            // ResetPassword
            "resetPassword": "Reset Password",
            "newPassword": "New Password",
            "confirmNewPassword": "Confirm New Password",
            "passwordsDoNotMatch": "Passwords do not match",
            "passwordResetSuccessful": "Password reset successful",
            // Orders
            "Transactions": "Transactions",
            "Seconds Acquired": "Seconds Acquired",
            "Date": "Date",
            "Amount": "Amount",
            "pencil": "Pencil",
            "pen": "Pen",
            "keyboard": "Keyboard",
            "beta_notice": "These prices are only available for the BETA version of the software.",
            // Showcase
            "basic_plan": "Basic Plan",
            "standard_plan": "Standard Plan",
            "premium_plan": "Premium Plan",
            "select_plan": "Select Plan",
            "per_month": "/month",
            "feature1": "Feature 1",
            "feature2": "Feature 2",
            "feature3": "Feature 3",
            "feature4": "Feature 4",
            // History
            "history": "History",
            // Home
            "transcriptor": "Transcriptor",
            "history": "History",
            // Market
            "market": "Market",
            "orders": "Orders",
            "logout": "Logout",
            // Rimuovi duplicati
            // "login": "Login",
            // TranscriptionSettingsModal
            "transcriptionSettings": "Transcription settings",
            "videoDurationText": "You selected a video that lasts:",
            "startTimeMinutesLabel": "Start time - Minutes:",
            "startTimeSecondsLabel": "Start time - Seconds:",
            "endTimeMinutesLabel": "End time - Minutes:",
            "endTimeSecondsLabel": "End time - Seconds:",
            "transcribeFullLabel": "Transcribe full file:",
            "transcribeButton": "Transcribe",
            "uploadingText": "Uploading in progress:",
            "processingFakeText": "Processing... Please wait a few minutes.",
            "transcriptionText": "Transcription in progress:",
            "backButton": "Back",
            // Dropzone
            "dropzoneText": "Drag and drop your file here or click to select a file",
        }
    },
    it: {
        translation: {
            // Transcriptor
            "Welcome to our transcription platform!": "Benvenuto sulla nostra piattaforma di trascrizione!",
            "Your friend code is: {{friendCode}}": "Il tuo codice amico è: {{friendCode}}",
            "Time available: {{time}}": "Tempo disponibile: {{time}}",
            "note1": "Nota: 1h a 0,99€.",
            "note2": "Per acquistare crediti e provare il servizio contatta:",
            "mail": "mail: support@letterlake.com",
            "telegram": "telegram: @Jason_Venom",
            "supportedFormats": "Formati supportati: m4a, mp3, webm, mp4, mpga, wav, mpeg, mov, mkv",
            "supportedLanguages": "Lingue supportate: ",
            "clickToExpand": "Clicca per espandere...",
            // SignUp
            "accountSignUp": "Registrazione Account",
            // Rimuovi duplicati
            // "email": "Email",
            // "password": "Password",
            "confirmPassword": "Conferma Password",
            "firstname": "Nome",
            "lastname": "Cognome",
            "birthDate": "Data di Nascita",
            "friendCode": "Codice Amico",
            "signUp": "Registrati",
            "allFieldsRequired": "Tutti i campi sono obbligatori",
            "invalidEmail": "Formato email non valido",
            "passwordMismatch": "Le password non corrispondono",
            // LogIn
            "signInToYourAccount": "Accedi al tuo account",
            // Rimuovi duplicati
            // "email": "Email",
            // "password": "Password",
            "login": "Accedi",
            "forgotPassword": "Password dimenticata?",
            "register": "Registrati",
            "badCredentials": "Credenziali non valide",
            // RequestResetPassword
            "requestPasswordReset": "Richiedi reimpostazione della password",
            // Rimuovi duplicati
            // "email": "Email",
            "requestReset": "Richiedi reimpostazione",
            "emailAddressIsRequired": "L'indirizzo email è richiesto",
            "emailSentSuccessfully": "Email inviata con successo",
            // ResetPassword
            "resetPassword": "Reimposta Password",
            "newPassword": "Nuova Password",
            "confirmNewPassword": "Conferma Nuova Password",
            "passwordsDoNotMatch": "Le password non corrispondono",
            "passwordResetSuccessful": "Reimpostazione della password riuscita",
            // Orders
            "Transactions": "Transazioni",
            "Seconds Acquired": "Secondi Acquistati",
            "Date": "Data",
            "Amount": "Importo",
            "pencil": "Matita",
            "pen": "Penna",
            "keyboard": "Tastiera",
            "beta_notice": "Questi prezzi sono disponibili solo per la versione BETA del software.",
            // Showcase
            "basic_plan": "Piano Base",
            "standard_plan": "Piano Standard",
            "premium_plan": "Piano Premium",
            "select_plan": "Seleziona Piano",
            "per_month": "/mese",
            "feature1": "Caratteristica 1",
            "feature2": "Caratteristica 2",
            "feature3": "Caratteristica 3",
            "feature4": "Caratteristica 4",
            // History
            "history": "Cronologia",
            // Home
            "transcriptor": "Trascrittore",
            "history": "Cronologia",
            // Market
            "market": "Mercato",
            "orders": "Ordini",
            "logout": "Disconnetti",
            "login": "Accedi",
            // TranscriptionSettingsModal
            "transcriptionSettings": "Impostazioni di trascrizione",
            "videoDurationText": "Hai selezionato un video che dura:",
            "startTimeMinutesLabel": "Inizio - Minuti:",
            "startTimeSecondsLabel": "Inizio - Secondi:",
            "endTimeMinutesLabel": "Fine - Minuti:",
            "endTimeSecondsLabel": "Fine - Secondi:",
            "transcribeFullLabel": "Trascrivi l'intero file:",
            "transcribeButton": "Trascrivi",
            "uploadingText": "Caricamento in corso:",
            "processingFakeText": "Elaborazione in corso... Attendere qualche minuto.",
            "transcriptionText": "Trascrizione in corso:",
            "backButton": "Indietro",
            // Dropzone
            "dropzoneText": "Trascina e rilascia il tuo file qui o clicca per selezionare un file",
        }
    },
    de: {
        translation: {
            // Transcriptor
            "Welcome to our transcription platform!": "Willkommen auf unserer Transkriptionsplattform!",
            "Your friend code is: {{friendCode}}": "Dein Freundschaftscode lautet: {{friendCode}}",
            "Time available: {{time}}": "Verfügbare Zeit: {{time}}",
            "note1": "Hinweis: 1 Stunde für 0,99€.",
            "note2": "Um Guthaben zu kaufen und den Service auszuprobieren, kontaktieren Sie:",
            "mail": "E-Mail: support@letterlake.com",
            "telegram": "Telegram: @Jason_Venom",
            "supportedFormats": "Unterstützte Formate: m4a, mp3, webm, mp4, mpga, wav, mpeg, mov, mkv",
            "supportedLanguages": "Unterstützte Sprachen: ",
            "clickToExpand": "Klicken Sie zum Erweitern...",
            
            // SignUp
            "accountSignUp": "Konto anmelden",
            "confirmPassword": "Passwort bestätigen",
            "firstname": "Vorname",
            "lastname": "Nachname",
            "birthDate": "Geburtsdatum",
            "friendCode": "Freundescode",
            "signUp": "Registrieren",
            "allFieldsRequired": "Alle Felder sind erforderlich",
            "invalidEmail": "Ungültiges E-Mail-Format",
            "passwordMismatch": "Passwörter stimmen nicht überein",
            
            // LogIn
            "signInToYourAccount": "Melden Sie sich bei Ihrem Konto an",
            "login": "Anmelden",
            "forgotPassword": "Passwort vergessen?",
            "register": "Registrieren",
            "badCredentials": "Ungültige Zugangsdaten",
            
            // RequestResetPassword
            "requestPasswordReset": "Passwort zurücksetzen anfordern",
            "requestReset": "Zurücksetzung anfordern",
            "emailAddressIsRequired": "E-Mail-Adresse ist erforderlich",
            "emailSentSuccessfully": "E-Mail erfolgreich gesendet",
            
            // ResetPassword
            "resetPassword": "Passwort zurücksetzen",
            "newPassword": "Neues Passwort",
            "confirmNewPassword": "Neues Passwort bestätigen",
            "passwordsDoNotMatch": "Passwörter stimmen nicht überein",
            "passwordResetSuccessful": "Passwort zurücksetzen erfolgreich",
            
            // Orders
            "Transactions": "Transaktionen",
            "Seconds Acquired": "Erworbene Sekunden",
            "Date": "Datum",
            "Amount": "Betrag",
            
            "pencil": "Bleistift",
            "pen": "Stift",
            "keyboard": "Tastatur",
            "beta_notice": "Diese Preise gelten nur für die BETA-Version der Software.",
            
            // Showcase
            "basic_plan": "Grundplan",
            "standard_plan": "Standardplan",
            "premium_plan": "Premiumplan",
            "select_plan": "Plan auswählen",
            "per_month": "/Monat",
            "feature1": "Funktion 1",
            "feature2": "Funktion 2",
            "feature3": "Funktion 3",
            "feature4": "Funktion 4",
            
            // History
            "history": "Verlauf",
            
            // Home
            "transcriptor": "Transkriptor",
            "history": "Verlauf",
            // Market
            "market": "Markt",
            "orders": "Bestellungen",
            "logout": "Abmelden",
            "login": "Anmelden",
            
            // TranscriptionSettingsModal
            "transcriptionSettings": "Transkriptionseinstellungen",
            "videoDurationText": "Sie haben ein Video ausgewählt, das dauert:",
            "startTimeMinutesLabel": "Startzeit - Minuten:",
            "startTimeSecondsLabel": "Startzeit - Sekunden:",
            "endTimeMinutesLabel": "Endzeit - Minuten:",
            "endTimeSecondsLabel": "Endzeit - Sekunden:",
            "transcribeFullLabel": "Vollständige Datei transkribieren:",
            "transcribeButton": "Transkribieren",
            "uploadingText": "Hochladen läuft:",
            "processingFakeText": "Verarbeitung... Bitte warten Sie einige Minuten.",
            "transcriptionText": "Transkription läuft:",
            "backButton": "Zurück",
            
            // Dropzone
            "dropzoneText": "Ziehen Sie Ihre Datei hierher, um sie abzulegen oder klicken Sie hier, um eine Datei auszuwählen",
        }
    },
    fr: {
        translation: {
            // Transcriptor
            "Welcome to our transcription platform!": "Bienvenue sur notre plateforme de transcription !",
            "Your friend code is: {{friendCode}}": "Votre code ami est : {{friendCode}}",
            "Time available: {{time}}": "Temps disponible : {{time}}",
            "note1": "Note : 1h à 0,99€.",
            "note2": "Pour acheter des crédits et essayer le service, contactez :",
            "mail": "mail : support@letterlake.com",
            "telegram": "telegram : @Jason_Venom",
            "supportedFormats": "Formats pris en charge : m4a, mp3, webm, mp4, mpga, wav, mpeg, mov, mkv",
            "supportedLanguages": "Langues prises en charge : ",
            "clickToExpand": "Cliquez pour développer...",
            
            // SignUp
            "accountSignUp": "Inscription au compte",
            "confirmPassword": "Confirmez le mot de passe",
            "firstname": "Prénom",
            "lastname": "Nom de famille",
            "birthDate": "Date de naissance",
            "friendCode": "Code ami",
            "signUp": "S'inscrire",
            "allFieldsRequired": "Tous les champs sont obligatoires",
            "invalidEmail": "Format d'e-mail invalide",
            "passwordMismatch": "Les mots de passe ne correspondent pas",
            
            // LogIn
            "signInToYourAccount": "Connectez-vous à votre compte",
            "login": "Connexion",
            "forgotPassword": "Mot de passe oublié ?",
            "register": "S'inscrire",
            "badCredentials": "Identifiants incorrects",
            
            // RequestResetPassword
            "requestPasswordReset": "Demander la réinitialisation du mot de passe",
            "requestReset": "Demander la réinitialisation",
            "emailAddressIsRequired": "L'adresse e-mail est requise",
            "emailSentSuccessfully": "E-mail envoyé avec succès",
            
            // ResetPassword
            "resetPassword": "Réinitialiser le mot de passe",
            "newPassword": "Nouveau mot de passe",
            "confirmNewPassword": "Confirmer le nouveau mot de passe",
            "passwordsDoNotMatch": "Les mots de passe ne correspondent pas",
            "passwordResetSuccessful": "Réinitialisation du mot de passe réussie",
            
            // Orders
            "Transactions": "Transactions",
            "Seconds Acquired": "Secondes acquises",
            "Date": "Date",
            "Amount": "Montant",
            
            "pencil": "Crayon",
            "pen": "Stylo",
            "keyboard": "Clavier",
            "beta_notice": "Ces prix ne sont disponibles que pour la version BETA du logiciel.",
            
            // Showcase
            "basic_plan": "Plan de base",
            "standard_plan": "Plan standard",
            "premium_plan": "Plan premium",
            "select_plan": "Sélectionnez un plan",
            "per_month": "/mois",
            "feature1": "Fonction 1",
            "feature2": "Fonction 2",
            "feature3": "Fonction 3",
            "feature4": "Fonction 4",
            
            // History
            "history": "Historique",
            
            // Home
            "transcriptor": "Transcripteur",
            "history": "Historique",
            // Market
            "market": "Marché",
            "orders": "Commandes",
            "logout": "Déconnexion",
            "login": "Connexion",
            
            // TranscriptionSettingsModal
            "transcriptionSettings": "Paramètres de transcription",
            "videoDurationText": "Vous avez sélectionné une vidéo qui dure :",
            "startTimeMinutesLabel": "Heure de début - Minutes :",
            "startTimeSecondsLabel": "Heure de début - Secondes :",
            "endTimeMinutesLabel": "Heure de fin - Minutes :",
            "endTimeSecondsLabel": "Heure de fin - Secondes :",
            "transcribeFullLabel": "Transcrire le fichier complet :",
            "transcribeButton": "Transcrire",
            "uploadingText": "Chargement en cours :",
            "processingFakeText": "Traitement en cours... Veuillez attendre quelques minutes.",
            "transcriptionText": "Transcription en cours :",
            "backButton": "Retour",
            
            // Dropzone
            "dropzoneText": "Faites glisser et déposez votre fichier ici ou cliquez pour sélectionner un fichier",
        }
    },
    es: {
        translation: {
            // Transcriptor
            "Welcome to our transcription platform!": "Bienvenido a nuestra plataforma de transcripción!",
            "Your friend code is: {{friendCode}}": "Tu código de amigo es: {{friendCode}}",
            "Time available: {{time}}": "Tiempo disponible: {{time}}",
            "note1": "Nota: 1 hora a 0,99€.",
            "note2": "Para comprar créditos y probar el servicio, contacta:",
            "mail": "correo: support@letterlake.com",
            "telegram": "telegrama: @Jason_Venom",
            "supportedFormats": "Formatos admitidos: m4a, mp3, webm, mp4, mpga, wav, mpeg, mov, mkv",
            "supportedLanguages": "Idiomas admitidos: ",
            "clickToExpand": "Haga clic para expandir...",
            
            // SignUp
            "accountSignUp": "Registro de cuenta",
            "confirmPassword": "Confirmar contraseña",
            "firstname": "Nombre de pila",
            "lastname": "Apellido",
            "birthDate": "Fecha de nacimiento",
            "friendCode": "Código de amigo",
            "signUp": "Registrarse",
            "allFieldsRequired": "Todos los campos son obligatorios",
            "invalidEmail": "Formato de correo electrónico no válido",
            "passwordMismatch": "Las contraseñas no coinciden",
            
            // LogIn
            "signInToYourAccount": "Inicia sesión en tu cuenta",
            "login": "Iniciar sesión",
            "forgotPassword": "¿Olvidaste tu contraseña?",
            "register": "Registrarse",
            "badCredentials": "Credenciales no válidas",
            
            // RequestResetPassword
            "requestPasswordReset": "Solicitar restablecimiento de contraseña",
            "requestReset": "Solicitar restablecimiento",
            "emailAddressIsRequired": "Se requiere una dirección de correo electrónico",
            "emailSentSuccessfully": "Correo electrónico enviado con éxito",
            
            // ResetPassword
            "resetPassword": "Restablecer contraseña",
            "newPassword": "Nueva contraseña",
            "confirmNewPassword": "Confirmar nueva contraseña",
            "passwordsDoNotMatch": "Las contraseñas no coinciden",
            "passwordResetSuccessful": "Restablecimiento de contraseña exitoso",
            
            // Orders
            "Transactions": "Transacciones",
            "Seconds Acquired": "Segundos Adquiridos",
            "Date": "Fecha",
            "Amount": "Cantidad",
            
            "pencil": "Lápiz",
            "pen": "Bolígrafo",
            "keyboard": "Teclado",
            "beta_notice": "Estos precios solo están disponibles para la versión BETA del software.",
            
            // Showcase
            "basic_plan": "Plan Básico",
            "standard_plan": "Plan Estándar",
            "premium_plan": "Plan Premium",
            "select_plan": "Seleccionar Plan",
            "per_month": "/mes",
            "feature1": "Característica 1",
            "feature2": "Característica 2",
            "feature3": "Característica 3",
            "feature4": "Característica 4",
            
            // History
            "history": "Historial",
            
            // Home
            "transcriptor": "Transcriptor",
            "history": "Historial",
            // Market
            "market": "Mercado",
            "orders": "Pedidos",
            "logout": "Cerrar sesión",
            "login": "Iniciar sesión",
            
            // TranscriptionSettingsModal
            "transcriptionSettings": "Configuración de transcripción",
            "videoDurationText": "Has seleccionado un video que dura:",
            "startTimeMinutesLabel": "Hora de inicio - Minutos:",
            "startTimeSecondsLabel": "Hora de inicio - Segundos:",
            "endTimeMinutesLabel": "Hora de finalización - Minutos:",
            "endTimeSecondsLabel": "Hora de finalización - Segundos:",
            "transcribeFullLabel": "Transcribir archivo completo:",
            "transcribeButton": "Transcribir",
            "uploadingText": "Carga en progreso:",
            "processingFakeText": "Procesando... Espere unos minutos.",
            "transcriptionText": "Transcripción en progreso:",
            "backButton": "Atrás",
            
            // Dropzone
            "dropzoneText": "Arrastra y suelta tu archivo aquí o haz clic para seleccionar un archivo",
        }
    }            
    // Aggiungi altre lingue qui
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: navigator.language, // Ottiene la lingua del browser
        fallbackLng: "en", // lingua di fallback

        interpolation: {
            escapeValue: false // reattivo alla fuga dei valori xss
        }
    });

export default i18n;
