import './css/LogIn.scss';
import React, { useContext, useState, useCallback, useEffect } from 'react';
import { BrowserRouter as Route, useNavigate } from 'react-router-dom'

import Card from '../components/Card';
import CustomButton from '../components/CustomButton';
import CustomInput from '../components/CustomInput';

import Axios from '../helpers/axios';
import { ctxSession, ctxSnackbar } from '../store';

import { UserData_model } from '../models/data/UserData_model';
import { SessionModel } from '../models';
import formDataValidator from '../helpers/formData';

import { decodeToken } from 'react-jwt';

import config from '../config';
import importer from '../helpers/importer';

import { useTranslation } from 'react-i18next';

const LogIn = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const session = useContext(ctxSession);
    const snackbar = useContext(ctxSnackbar);

    const [loading, setLoading] = useState<boolean>(false);
    const [errorAnimation, setErrorAnimation] = useState<boolean | string>(false);
    const [error, setError] = useState<boolean | string>(false);

    useEffect(() => {
        if (session && session.data?.token) {
            navigate('/');
        }
    }, [session])


    const animateError = () => {
        setErrorAnimation(true);
        setTimeout(() => {
            setErrorAnimation(false);
        }, 100);
    }

    const logIn = (e: any) => {
        e.preventDefault();

        const formData: any = formDataValidator(e.target, 'object');


        if (formData.email && formData.password && formData.password.length >= 8) {
            setError(false);
            setLoading(true);
            // console.log(formData)


            const onSuccess = (response: any) => {
                if (response.data.token) {

                    localStorage.setItem('session', "true");

                    let userData: UserData_model | null = decodeToken(response.data.token);

                    if (session && session.set) {
                        const sess: SessionModel = {
                            userData,
                        };

                        session.set(sess)
                    }
                    navigate('/');
                } else {
                    console.log('no Token');
                    return;
                }
            }

            const onError = (response: any) => {
                setLoading(false);
                // Mostra un messaggio di errore generico o specifico, a seconda della risposta
                snackbar?.set({ message: t('badCredentials'), severity: 'error', millis: 3000 })
            }

            //console.log(formData)
            Axios(session, 'post', `${config.api}/auth/`, onSuccess, onError, formData);
        } else {
            setError('bad_credentials');
            animateError();
        }
    }

    return (
        <div id="Auth" >
            <div className="logo">
                <img src={importer.img.require('logo.svg')} alt="" />
            </div>
            <Card type="floating" class="shadow">
                <div className="logo">
                    <h4 className='mb-0'>{t('signInToYourAccount')}</h4>
                </div>
                <div className='my-4'>
                    <form onSubmit={logIn}>
                        <div className="mb-3">
                            <CustomInput name="email" variant="cloud" type="email" /*img={importer.ic.require('user.svg')}*/ placeholder={t('email')} class="mb-2"></CustomInput>
                            <CustomInput name="password" variant="cloud" type="password" /*img={importer.ic.require('password.svg')}*/ placeholder={t('password')} showable={true}></CustomInput>
                        </div>

                        <div>
                            <CustomButton variant="primary" type={"submit"} class="w-100 mb-2">{t('login')}</CustomButton>
                            <a className='' onClick={() => { navigate('/RequestResetPassword') }}><strong>{t('forgotPassword')}</strong></a>
                            <a className='' onClick={() => { navigate('/SignUp') }}><strong>{t('register')}</strong></a>
                        </div>
                    </form>
                </div>
            </Card>
        </div>
    );
};
export default React.memo(LogIn);


// http://localhost:3001/