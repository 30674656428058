import './css/Site.scss';
import React, { useState, useMemo, useEffect, useContext, useCallback } from 'react';


import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import { ctxSession, ctxSnackbar } from '../store';


import { SessionModel } from '../models';
import { UserData_model } from '../models/data/UserData_model';

import { decodeToken } from 'react-jwt';

import LogIn from './LogIn';
import SignUp from './SignUp';
import RequestResetPassword from './RequestResetPassword';
import ResetPassword from './ResetPassword';

import Home from './Home';
import Transcriptor from './Transcriptor';
import History from './History';
import Market from './Market';
import Orders from './Orders';
//import Profile from './Profile';


import CustomSnackbarContainer from '../components/CustomSnackbarContainer';
import Axios from '../helpers/axios';
import config from '../config';

import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

const Site = () => {

  const session = useContext(ctxSession);
  const snackbar = useContext(ctxSnackbar);


  useEffect(() => {
    if (session && localStorage.getItem('session') && !session?.data?.userData) {

      const onSuccess = (response: any) => {
        if (session && session.set) {
          const decodedToken: UserData_model | null = response.data;

          const sess: SessionModel = {
            userData: decodedToken,
          }
          session.set(sess)
        }
      }
      Axios(session, 'get', `${config.api}/auth/canILog`, onSuccess);


    }
  }, []);



  const particlesInit = useCallback(async (engine: any) => {
    //console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container: any) => {
    //await console.log(container);
  }, []);


  return (
    // <Fade in={true}>
    <div id="Site">

      <Particles
        id="tsparticles"
        options={

          {

            background: {
              color: {
                value: "#ffffff"
              },
              image: `url('${require('../../assets/images/paper.png')}')`, //require('../../assets/images/authBG.jpg'),//"url('../../assets/images/authBG.jpg')",//require('../../assets/images/authBG.jpg'),//"url()",
              position: "50% 50%",
              repeat: "no-repeat",
              size: "cover",
              opacity: 0.1
            },

            fpsLimit: 60,
            particles: {
              color: {
                value: ['#00f', '#f00', "#000"],
              },

              move: {
                attract: { enable: false, rotateX: 600, rotateY: 1200 },
                enable: true,
                out_mode: "out",
                random: false,
                speed: 1,
                straight: false
              },
              rotate: {
                animation: {
                  enable: true,
                  speed: 8,
                  sync: false
                }
              },
              number: {
                density: {
                  enable: true,
                  area: 800
                },
                value: 52
              },
              opacity: {
                animation: { enable: true, minimumValue: .5, speed: .5, sync: false },
                random: false,
                value: 1
              },
              shape: {
                character: [
                  {
                    font: "Verdana",
                    style: "",
                    value: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ".split(""),//["Bla", "BlaBlaBla", "Video", "Audio", "Text"],
                    weight: "400"
                  }
                ],
                type: "char"
              },
              size: {
                anim: { enable: true, minimumValue: 8, speed: 10, sync: false },
                random: { minimumValue: 8, enable: true },
                value: 24
              }
            },
            detectRetina: true
          }
        }
        // url="http://foo.bar/particles.json"
        init={particlesInit} loaded={particlesLoaded}
      />
      <div id='content'>
        <CustomSnackbarContainer>

          <Routes>
            <Route path="/" element={<Home />}>
              <Route path="/" element={<Transcriptor />} />
              <Route path="/history" element={<History />} />
              <Route path="/Market" element={<Market />} />
              <Route path="/Orders" element={<Orders />} />
              {/* <Route path="/profile" element={<Profile />} /> */}
            </Route>


            <Route path="/login" element={<LogIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/requestresetpassword" element={<RequestResetPassword />} />
            <Route path="/auth/resetpassword/:token" element={<ResetPassword />} />

            <Route
              path="*"
              element={<Navigate to="/" />}
            />

          </Routes>

        </CustomSnackbarContainer>
        <h4 className='version'>BETA</h4>
      </div>
    </div>

    // </Fade>
  );
};
export default React.memo(Site);
