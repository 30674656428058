import './css/Dropzone.scss';

import React, { useRef, useState, useContext, useCallback, useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
// import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Axios from '../helpers/axios';

import CustomButton from './CustomButton';


import { ctxSession } from '../store';

import { useTranslation } from 'react-i18next';

function Dropzone(props: any) {
    const { t } = useTranslation();

    const session = useContext(ctxSession);

    const randID = Math.random().toString();
    const [progress, setProgress] = useState<number>(0);
    const [uploading, setUploading] = useState(false); // State to track uploading status

    let className = 'dropzone ';

    if (props.class) {
        className = className + ' ' + props.class;
    }

    const overrideDefault = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    };



    const inputRef = useRef<any>();

    return (
        <label
            className={className + (uploading ? ' isUploading' : '')}
            htmlFor={randID}
            onDragOver={(e) => overrideDefault(e)}
            onDragEnter={(e) => overrideDefault(e)}
            onDragLeave={(e) => overrideDefault(e)}
            onDrop={(e) => {
                overrideDefault(e);
                if (props.onFileDropped) {
                    const files = e.dataTransfer.files; // Ottieni i file trascinati
                    if (files.length > 0) {
                        const eventWrapper = { target: { files } };
                        props.onFileDropped(eventWrapper);
                    }
                }
            }}

        >
            <p>{t('dropzoneText')} </p>
            <span className="icon">
                <CloudUploadIcon />
            </span>

            {props.url && props.showProgressBar && progress && <LinearProgress variant="determinate" value={progress} />}

            <input
                ref={inputRef}
                id={randID}
                type="file"
                accept={props.accept}
                multiple={props.multiple ? true : false}
                onChange={(e) => {
                    // console.log(e);
                    if (props.onFileDropped) {
                        props.onFileDropped(e);
                    }
                    // Resetta il valore dell'input file per assicurarti che l'evento onChange venga attivato anche quando viene selezionato di nuovo lo stesso file.
                    if (inputRef.current) {
                        inputRef.current.value = "";
                    }
                }}
            />


        </label>
    );
}

export default React.memo(Dropzone);
