import './css/Orders.scss';
import React, { useState, useEffect, useContext } from 'react';

import Axios from '../helpers/axios';
import config from '../config';

import { ctxSession } from '../store';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';

import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';

import { useTranslation } from 'react-i18next';

function Orders() {
  const { t } = useTranslation();

  const session = useContext(ctxSession);

  const [transactions, setTransactions] = useState<any[]>([]);

  useEffect(() => {
    const onSuccess = (response: any) => {
      setTransactions(response.data.payments)
    };

    const onError = () => {

    }

    Axios(session, 'get', `${config.api}/payment-history`, onSuccess, onError);

  }, []);




  return (
    <div id="orders">

      <h1><HistoryRoundedIcon /> {t('Transactions')}</h1>

      <div className="history">
        <div className="list">

          {transactions.map((transaction: any, k: number) =>
            <div className='item' key={k}>
              <div className='desc'>
                <div className="icon">
                  <AttachMoneyRoundedIcon />
                </div>
                <div className="title">
                  {`${t('Seconds Acquired')}: ${transaction.transcription_seconds}`}
                </div>
              </div>
              <div className="details">
                {`${t('Date')}: ${new Date(transaction.created_at).toLocaleDateString()}`} <br />{`
      ${t('Amount')}: ${transaction.amount} ${transaction.currency || 'EUR'}`}
              </div>
            </div>
          )}

        </div>
      </div>
    </div>
  );
};

export default Orders;
