import React, { useState, useMemo } from 'react';
import './App.css';


import { ctxSession } from './store';
import { SessionModel, SnackbarModel } from './models';

import Site from './viewmodels/Site';
import Auth from './viewmodels/Auth';
import LogIn from './viewmodels/LogIn';
import Home from './viewmodels/Home';
// import Headbar from './components/Headbar';

function App() {
    
    // let main = <div>No Website</div>;

    const [session, setSession] = useState<SessionModel | null>(null);

    return (
        <ctxSession.Provider value={{data: session, set: setSession}} >
            <Site />
        </ctxSession.Provider>
    );
}

export default App;
