import './css/Market.scss';

import React, { useState, useCallback, useContext } from 'react';

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import DrawRoundedIcon from '@mui/icons-material/DrawRounded';
import KeyboardAltRoundedIcon from '@mui/icons-material/KeyboardAltRounded';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import RocketLaunchRoundedIcon from '@mui/icons-material/RocketLaunchRounded';

import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';

import StorefrontRoundedIcon from '@mui/icons-material/StorefrontRounded';


import { useTranslation } from 'react-i18next';

import Axios from "../helpers/axios";

import { ctxSession } from '../store';

function Market() {
  const { t } = useTranslation();
  const session = useContext(ctxSession);

  const initialOptions = {
    clientId: "Adpbk7Bo4ZybS9IbaL5wiV0wJRB4vpSDnWpp7SUqNF9RHFmhxY6uyM4fuN0m1ty02Vn6LitbP8kV-rMF", // clientId in sandbox: test
    currency: "EUR",
   "enable-funding": "",
   "disable-funding": "paylater,venmo,card,mybank",
    "data-sdk-integration-source": "integrationbuilder_sc",
  };

  const [message, setMessage] = useState("");
  const [selectedProductId, setSelectedProductId] = useState<number | null>(null);
  
  
  const createOrder = async (productId: number): Promise<string> => {
    return new Promise(async (resolve, reject) => {
      const data = {
        productId: productId,
      };

      const onSuccess = (response: any) => {
        const orderData = response.data;

        if (orderData.id) {
          resolve(orderData.id);
        } else {
          const errorDetail = orderData?.details?.[0];
          const errorMessage = errorDetail
            ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
            : JSON.stringify(orderData);

          reject(new Error(errorMessage));
        }
      };

      const onError = (error: any) => {
        console.error(error);
        setMessage(`Could not initiate PayPal Checkout...${error}`);
        reject(error);
      };

      Axios(session, "post", "/api/orders", onSuccess, onError, data);
    });
  };

  const renderPayPalButtons = useCallback( (productId: number) => {
    return (
      <PayPalButtons
        style={{
          shape: "rect",
          layout: "vertical",
        }}
        createOrder={async () => {
          // Qui utilizziamo productId invece di secondsToBuy
          return await createOrder(productId);
        }}
        onApprove={async (data:any, actions:any) => {
          try {
            // Configurazione Axios
            const onSuccess = (response: any) => {
              // Gestisci la risposta di successo qui
              const orderData = response.data;
              console.log("OrderData" + orderData);
              const errorDetail = orderData?.details?.[0];
  
              if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                return actions.restart();
              } else if (errorDetail) {
                throw new Error(
                  `${errorDetail.description} (${orderData.debug_id})`,
                );
              } else {
                const transaction = orderData.purchase_units[0].payments.captures[0];
                setMessage(
                  `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`,
                );
                console.log(
                  "Capture result",
                  orderData,
                  JSON.stringify(orderData, null, 2),
                );
              }
            };
  
            const onError = (error: any) => {
              console.error(error);
              setMessage(`Sorry, your transaction could not be processed...${error}`);
            };
            console.log("OrderID" + data.orderID);
            Axios(session, "post", `/api/orders/${data.orderID}/capture`, onSuccess, onError, {});
          } catch (error) {
            console.error(error);
            setMessage(`Sorry, your transaction could not be processed...${error}`);
          }
        }}
      />
    );
  }, [selectedProductId]);

  return (
    <>
      <div id="market">

        <h1><StorefrontRoundedIcon /> {t('market')}</h1>
          <h5>{t('beta_notice')}</h5>
          <div className="prices-container">
            
            <PayPalScriptProvider options={initialOptions}>
            <div className='prices'>


              <div className='pricebox'>
                <div className='pricebox-head'>
                  <h1><CreateRoundedIcon /></h1>
                  <h2> {t('pencil')}</h2>
                </div>

                <div className='pricebox-body'>

                  <div className='package'>
                    <div className='price'>
                      <div className='hours'>5h</div>
                      <div className='amount'>4.95€</div>
                      <div className='ratio'>0.99€/h</div>
                    </div>

                    <div className="purchase">
                      {renderPayPalButtons(1)}
                      <div className="contacts">
                        
                        <a className="mail" href="mailto:support@letterlake.com"><EmailRoundedIcon /></a>
                        <a className="telegram" href="https://telegram.me/Jason_Venom" target='_blank'><SendRoundedIcon className="telegram" /></a>
                    
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className='pricebox'>
                <div className='pricebox-head'>
                  <h1><DrawRoundedIcon /></h1>
                  <h2>{t('pen')}</h2>
                </div>

                <div className='pricebox-body'>

                  <div className='package'>
                    <div className='price'>
                      <div className='hours'>10h</div>
                      <div className='amount'>9.30€</div>
                      <div className='ratio'>0.93€/h</div>
                    </div>


                    <div className="purchase">
                      {renderPayPalButtons(2)}
                      <div className="contacts">
                        
                        <a className="mail" href="mailto:support@letterlake.com"><EmailRoundedIcon /></a>
                        <a className="telegram" href="https://telegram.me/Jason_Venom" target='_blank'><SendRoundedIcon className="telegram" /></a>
                    
                      </div>
                    </div>

                  </div>
                </div>
              </div>


              <div className='pricebox'>
                <div className='pricebox-head'>
                  <h1><KeyboardAltRoundedIcon /></h1>
                  <h2>{t('keyboard')}</h2>
                </div>

                <div className='pricebox-body'>

                  <div className='package'>
                    <div className='price'>
                      <div className='hours'>20h</div>
                      <div className='amount'>17€</div>
                      <div className='ratio'>0.85€/h</div>
                    </div>


                    <div className="purchase">
                      {renderPayPalButtons(3)}
                      <div className="contacts">
                        
                        <a className="mail" href="mailto:support@letterlake.com"><EmailRoundedIcon /></a>
                        <a className="telegram" href="https://telegram.me/Jason_Venom" target='_blank'><SendRoundedIcon className="telegram" /></a>
                    
                      </div>
                    </div>

                  </div>
                </div>
              </div>


              <div className='pricebox'>
                <div className='pricebox-head'>
                  <h1><SmartToyOutlinedIcon /></h1>
                  <h2>Robot</h2>
                </div>

                <div className='pricebox-body'>

                  <div className='package'>
                    <div className='price'>
                      <div className='hours'>30h</div>
                      <div className='amount'>23.70€</div>
                      <div className='ratio'>0.79€/h</div>
                    </div>


                    <div className="purchase">
                      {renderPayPalButtons(4)}
                      <div className="contacts">
                        
                        <a className="mail" href="mailto:support@letterlake.com"><EmailRoundedIcon /></a>
                        <a className="telegram" href="https://telegram.me/Jason_Venom" target='_blank'><SendRoundedIcon className="telegram" /></a>
                    
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className='pricebox'>
                <div className='pricebox-head'>
                  <h1><RocketLaunchRoundedIcon /></h1>
                  <h2>CUSTOM</h2>
                </div>

                <div className='pricebox-body'>

                  <div className='package'>
                    <div className='price'>
                      <div className='hours'>?h</div>
                      <div className='amount'>?€</div>
                      <div className='ratio'>?€/h</div>
                    </div>


                    <div className="purchase">
                      <div className="contacts">
                        <a className="mail" href="mailto:support@letterlake.com"><EmailRoundedIcon /></a>
                        <a className="telegram" href="https://telegram.me/Jason_Venom" target='_blank'><SendRoundedIcon className="telegram" /></a>
                    
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
            </PayPalScriptProvider>
          </div>

      </div>
    </>
  );
};

export default Market;
