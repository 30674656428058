import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '../components/Card';
import CustomButton from '../components/CustomButton';
import CustomInput from '../components/CustomInput';
import Axios from '../helpers/axios';
import config from '../config';
import importer from '../helpers/importer';
import { useTranslation } from 'react-i18next';

import { ctxSession, ctxSnackbar } from '../store';

const SignUp: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const session = useContext(ctxSession);
  const snackbar = useContext(ctxSnackbar);


  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [errorAnimation, setErrorAnimation] = useState(false);

  const animateError = () => {
    setErrorAnimation(true);
    setTimeout(() => {
      setErrorAnimation(false);
    }, 1000); // Durata dell'animazione in millisecondi
  };

  const capitalizeWords = (input: string): string => {
    return input
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
      .trim();
  };

  const validateEmail = (email: string): boolean => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email.toLowerCase());
  };

  const register = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);

    let firstname = formData.get("firstname") as string;
    let lastname = formData.get("lastname") as string;

    firstname = capitalizeWords(firstname);
    lastname = capitalizeWords(lastname);

    const data = {
      email: formData.get("email") as string,
      password: formData.get("password") as string,
      confirmPassword: formData.get("confirmPassword") as string,
      firstname,
      lastname,
      birthday: formData.get("birthday") as string,
      friendCode: formData.get("friendCode") as string,
    };

    if (!validateEmail(data.email)) {
      setError("Invalid email format");
      return;
    }

    if (data.password !== data.confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    if (data.email && data.password && data.firstname && data.lastname && data.birthday) {
      setLoading(true);

      const onSuccess = (response: any) => {
        navigate('/login');
        // Messaggio di conferma
        snackbar?.set({ message: "Verification email has been sent", severity: 'success', millis: 3000 });
      }

      const onError = (response: any) => {
        console.error("Error fetching transcriptionSeconds:", response);
        response.response.data.errors.forEach((v: any) => {
          snackbar?.set({ message: v.msg, severity: 'error', millis: 3000 });
        });
      }

      Axios(session, 'post', `${config.api}/register`, onSuccess, onError, data);



    } else {
      setError("All fields are required");
    }
  };

  return (
    <div id="Auth">
      <div className="logo">
        <img src={importer.img.require('logo.svg')} alt="" />
      </div>
      <Card type="floating" className="shadow">
        <div className="logo">

          <h4 className='mb-0'>{t('accountSignUp')}</h4>
        </div>
        <div className="my-4">
          <form onSubmit={register}>
            <div className="mb-3">
              <CustomInput name="email" variant="cloud" type="email" label={t('email')} required={true} />
              <CustomInput name="password" classes="mb-3" variant="cloud" type="password" label={t('password')} required={true} showStrength={true} min={8} />
              <CustomInput name="confirmPassword" variant="cloud" type="password" label={t('confirmPassword')} required={true} min={8} />
              <CustomInput name="firstname" variant="cloud" type="text" label={t('firstname')} required={true} />
              <CustomInput name="lastname" variant="cloud" type="text" label={t('lastname')} required={true} />
              <CustomInput name="birthday" style={{ height: '39px' }} variant="cloud" type="date" label={t('birthDate')} required={true} />
              <CustomInput name="friendCode" variant="cloud" type="text" label={t('friendCode')} />
            </div>
            <div>
              <CustomButton variant="primary" type={"submit"} class="w-100 mb-2">{t('signUp')}</CustomButton>
            </div>
          </form>
        </div>
        <a className='' onClick={() => { navigate('/login') }}><strong>{t('back_to_login')}</strong></a>

      </Card>
    </div>
  );
};

export default React.memo(SignUp);
