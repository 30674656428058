import React, { useState, useContext, useEffect } from 'react';
import CustomButton from './CustomButton';
import './css/TranscriptionSettingsModal.css';
import Axios from '../helpers/axios';
import config from '../config';
import { ctxSession, ctxSnackbar } from '../store';
import ProgressBar from 'react-bootstrap/ProgressBar';
import io from 'socket.io-client';
import { Socket } from 'socket.io-client';

import { useTranslation } from 'react-i18next';

const TranscriptionSettingsModal: React.FC<{ file: File, availableSeconds: number | null, onClose: () => void, updateParentTranscriptionSeconds: (newSeconds: number) => void }> = ({ file, availableSeconds, onClose, updateParentTranscriptionSeconds }) => {
    const { t } = useTranslation();

    const [startTimeMinutes, setStartTimeMinutes] = useState<number>(0);
    const [startTimeSeconds, setStartTimeSeconds] = useState<number>(0);
    const [endTimeMinutes, setEndTimeMinutes] = useState<number>(0);
    const [endTimeSeconds, setEndTimeSeconds] = useState<number>(0);
    const [transcribeFull, setTranscribeFull] = useState<boolean>(false);
    const [videoDuration, setVideoDuration] = useState<number | null>(null);
    const [isUploading, setIsUploading] = useState(false);

    const [disableButton, setDisableButton] = useState<boolean>(false);
    useEffect(() => {
        if (!availableSeconds) {
            setDisableButton(true);
        } else {
            const startMinSeconds = startTimeMinutes * 60 + startTimeSeconds;
            const endMinSeconds = endTimeMinutes * 60 + endTimeSeconds;
            const totalSeconds = endMinSeconds - startMinSeconds;


            if ((videoDuration && videoDuration > availableSeconds)) {
                if ((totalSeconds && totalSeconds > availableSeconds)) {
                    setDisableButton(true);
                    return;
                } else if (totalSeconds) {
                    setDisableButton(false);
                    return;
                }
                else {
                    setDisableButton(true);
                    return;
                }
            }

            // if(videoDuration && videoDuration > availableSeconds){
            //     setDisableButton(true);

            // }else{
            //     setDisableButton(false);
            // }
        }
    }, [availableSeconds, videoDuration, endTimeMinutes, endTimeSeconds, startTimeSeconds, startTimeMinutes])

    const [uploadProgress, setUploadProgress] = useState(0); // Aggiunto
    const [transcriptionProgress, setTranscriptionProgress] = useState(0); // Aggiunto
    const [socket, setSocket] = useState<Socket | null>(null);
    const [isSocketInitialized, setIsSocketInitialized] = useState(false);

    const session = useContext(ctxSession);
    const snackbar = useContext(ctxSnackbar);

    function base64ToArrayBuffer(base64: any) {
        const binaryString = window.atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    }


    const initializeSocket = () => {
        // Inizializza il socket
        //const token = localStorage.getItem('token');
        const newSocket = io('https://letterlake.com');
        setSocket(newSocket);
        newSocket.emit('init', { userId: session?.data?.userData?.id });

        // Gestione degli eventi del socket
        newSocket.on('progress', (data) => {
            setTranscriptionProgress(data);
        });

        newSocket.on('transcription_complete', () => {
            //console.log('Transcription complete. Disconnecting...');
            newSocket.disconnect();
        });

        newSocket.on('transcription_data', (data) => {
            //console.log("Ricevuto dati di trascrizione:", data);
            const { fileBuffer, remainingSeconds, outputFileName } = data;

            // Decodifica la stringa base64 in un array di byte
            const byteArrayBuffer = base64ToArrayBuffer(fileBuffer);
            const byteArray = new Uint8Array(byteArrayBuffer);

            // Converte il buffer in Blob
            const blob = new Blob([byteArray.buffer], { type: 'text/plain' });

            // Crea un URL per il Blob
            const url = window.URL.createObjectURL(blob);

            // Avvia il download
            const a = document.createElement("a");
            a.href = url;
            a.download = outputFileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            // Rilascia l'URL del Blob
            window.URL.revokeObjectURL(url);

            // Aggiorna i secondi rimanenti nel componente padre
            updateParentTranscriptionSeconds(Math.round(remainingSeconds));
            onClose();
        });




        return newSocket;
    };

    // Chiudo il socket quando il componente è smontato
    useEffect(() => {
        if (uploadProgress === 100 && !isSocketInitialized) {
            initializeSocket();
            setIsSocketInitialized(true);
        }

        return () => {
            if (socket) {
                socket.disconnect();
            }
        };
    }, [uploadProgress, isSocketInitialized]);

    const handleMetadataLoaded = (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
        setVideoDuration(e.currentTarget.duration);
    };

    const [processingFake, setProcessingFake] = useState(false); // Stato per l'elaborazione fittizia

    // Aggiungi un effetto per gestire il cambiamento di uploadProgress e transcriptionProgress
    useEffect(() => {
        if (uploadProgress === 100 && transcriptionProgress === 0) {
            setProcessingFake(true); // Inizia l'elaborazione fittizia solo dopo il completamento dell'upload
        }
        if (transcriptionProgress > 0) {
            setProcessingFake(false); // Smetti l'elaborazione fittizia quando inizia la trascrizione effettiva
        }
    }, [uploadProgress, transcriptionProgress, file.name]);

    const handleSubmit = async () => {
        setIsUploading(true);

        const settings = {
            startTimeMinutes,
            startTimeSeconds,
            endTimeMinutes,
            endTimeSeconds,
            transcribeFull
        };

        const formData = new FormData();
        formData.append('file', file);
        formData.append('settings', JSON.stringify(settings));

        // Recupera il token
        // const token = localStorage.getItem('token');

        const onSuccess = (response: any) => {
            // onClose();
        };

        const onError = (error: any) => {
            console.error("Error:", error);
            if (error.response && error.response.data) {
                snackbar?.set({ message: error.response.data.error, severity: 'error', millis: 3000 });
            }
            setIsUploading(false);
        };

        try {
            Axios(session, 'post', `${config.api}/transcribe`, onSuccess, onError, formData, {
                onUploadProgress: function (progressEvent: any) {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted);  // Imposta lo stato del progresso qui
                }
            });
        } catch (error) {
            console.error("Unexpected error:", error);
            snackbar?.set({ message: "Unexpected error", severity: 'error', millis: 3000 });
        } finally {
            //setIsUploading(false);
            // onClose();
        }
    };

    const secondsToTime = (seconds: number) => {
        const years = Math.floor(seconds / 31536000).toString();
        const months = Math.floor((seconds % 31536000) / 2592000).toString().padStart(2, '0');
        const days = (Math.floor((seconds % 31536000) / 86400) - (Number(months) * 30)).toString().padStart(2, '0');
        const hours = Math.floor(((seconds % 31536000) % 86400) / 3600).toString().padStart(2, '0');
        const minutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60).toString().padStart(2, '0');
        const secs = Math.floor((((seconds % 31536000) % 86400) % 3600) % 60).toString().padStart(2, '0');


        let str = new Array();
        // Costruiamo gradualmente la stringa del tempo
        if (years > '00') {
            str.push(years)
        }
        if (months > '00') {
            str.push(months)
        }
        if (days > '00') {
            str.push(days)
        }

        str.push(hours)
        str.push(minutes)
        str.push(secs)

        return str.join(':');
    };

    return (
        <div className="modal-background">
            <div className="modal-content">
                <h2>{t('transcriptionSettings')}</h2>
                {videoDuration && <p>{t('videoDurationText')} {new Date(videoDuration * 1000).toISOString().substring(11, 19)}</p>}
                {/* Qui puoi aggiungere ulteriori campi come la selezione della lingua */}
                <div className="label-input-group">
                    <label>{t('startTimeMinutesLabel')}</label>
                    <input type="number" value={startTimeMinutes} onChange={(e) => setStartTimeMinutes(Number(e.target.value))} min="0" />
                </div>
                <div className="label-input-group">
                    <label>{t('startTimeSecondsLabel')}</label>
                    <input type="number" value={startTimeSeconds} onChange={(e) => setStartTimeSeconds(Number(e.target.value))} min="0" max="59" />
                </div>
                <div className="label-input-group">
                    <label>{t('endTimeMinutesLabel')}</label>
                    <input type="number" value={endTimeMinutes} onChange={(e) => setEndTimeMinutes(Number(e.target.value))} min="0" />
                </div>
                <div className="label-input-group">
                    <label>{t('endTimeSecondsLabel')}</label>
                    <input type="number" value={endTimeSeconds} onChange={(e) => setEndTimeSeconds(Number(e.target.value))} min="0" max="59" />
                </div>
                <div className="label-input-group">
                    <label>{t('transcribeFullLabel')}</label>
                    <input type="checkbox" checked={transcribeFull} onChange={(e) => setTranscribeFull(e.target.checked)} />
                </div>
                {/* Per l'upload */}
                {isUploading && (
                    <div className="progress-container">
                        <p>{t('uploadingText')}</p>
                        <ProgressBar animated now={uploadProgress} label={`${uploadProgress}%`} />
                    </div>
                )}

                {processingFake && (
                    <div className="progress-container">
                        <p>{t('processingFakeText')}</p>
                    </div>
                )}

                {/* Per la trascrizione */}
                {isUploading && uploadProgress === 100 && (
                    <div className="progress-container">
                        <p>{t('transcriptionText')}</p>
                        <ProgressBar animated now={transcriptionProgress} label={`${transcriptionProgress}%`} />
                    </div>
                )}
                {
                    disableButton && availableSeconds && videoDuration &&
                    <h5 style={{ color: 'red', fontWeight: 'bold' }}>{t('Time available: {{time}}', { time: secondsToTime(availableSeconds) })}</h5>

                }
                <div className='button-group'>
                    <CustomButton onClick={onClose} style={{ marginLeft: '10px' }}>{t('backButton')}</CustomButton>
                    <CustomButton onClick={handleSubmit} disabled={disableButton}>{t('transcribeButton')}</CustomButton>
                </div>

                <video onLoadedMetadata={handleMetadataLoaded} style={{ display: 'none' }} src={URL.createObjectURL(file)}></video>
            </div>
        </div>
    );
};

export default TranscriptionSettingsModal;
