const dev = {
    api: `https://letterlake.com:8080`
};


const prod = {
    api: `${window.location.origin}`
};


const config = process.env.NODE_ENV === "development" ? dev : prod;

export default config;