import React, { useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '../components/Card';
import CustomButton from '../components/CustomButton';
import CustomInput from '../components/CustomInput';
import Axios from '../helpers/axios';
import config from '../config';
import importer from '../helpers/importer';

import { ctxSession, ctxSnackbar } from '../store';

import { useTranslation } from 'react-i18next';

const ResetPassword: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const session = useContext(ctxSession);
  const snackbar = useContext(ctxSnackbar);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const { token } = useParams(); // Preleva il token dall'URL

  const resetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);

    const data = {
      token: token,
      newPassword: formData.get("newPassword") as string,
      confirmNewPassword: formData.get("confirmNewPassword") as string,
    };

    if (data.newPassword !== data.confirmNewPassword) {
      setError(t("passwordsDoNotMatch"));
      return;
    }

    setLoading(true);

    const onSuccess = (response: any) => {
      navigate('/login');
      snackbar?.set({ message: t("passwordResetSuccessful"), severity: 'success', millis: 3000 });
    }

    const onError = (response: any) => {
      response.response.data.errors.forEach((v: any) => {
        snackbar?.set({ message: v.msg, severity: 'error', millis: 3000 });
      });
    }

    Axios(session, 'post', `${config.api}/reset-password`, onSuccess, onError, data);
  };

  return (
    <div id="Auth">
      <div className="logo">
        <img src={importer.img.require('logo.svg')} alt="" />
      </div>
      <Card type="floating" className="shadow">
        <div className="logo">
          <img className={`mb-3 ${loading ? 'spin' : ''}`} src={/* importer.img.require('Logo.svg')*/ ""} alt="" />
          <h4 className='mb-0'>{t("resetPassword")}</h4>
        </div>
        <div className="my-4">
          <form onSubmit={resetPassword}>
            <div className="mb-3">
              <CustomInput name="newPassword" variant="cloud" type="password" placeholder={t("newPassword")} required={true} min={8} />
              <CustomInput name="confirmNewPassword" variant="cloud" type="password" placeholder={t("confirmNewPassword")} required={true} min={8} />
            </div>
            <div>
              <CustomButton variant="primary" type={"submit"} className="w-100 mb-2">{t("resetPassword")}</CustomButton>
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
};

export default React.memo(ResetPassword);
